<template>
  <div class="settings-main">
    <div class="page_header">
      <h1>Настройки</h1>
    </div>
    <div class="page_content">
      <p>Выберите нужный раздел настроек</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Main"
}
</script>
